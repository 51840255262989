import { addYears } from "date-fns";
import { Education, Experience, TimelineCandidateEducation } from "./Models";
import { CompanyLocationExists } from "./WebCalls";
import { v4 as uuidv4 } from "uuid";
import useEducationState from "./hooks/useEducationState";
import useCareerTimelineState from "./hooks/useCareerTimelineState";
import useExperienceState from "./hooks/useExperienceState";

export function isFileValid(fileName: string) {
  const fileExtension = fileName.split(".").pop()?.toLowerCase();
  return (
    fileExtension === "pdf" ||
    fileExtension === "doc" ||
    fileExtension === "docx"
  );
}

export function calculateTotalMonths(startDate: Date, endDate: Date) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth())
  );
}

export function calculatePeriodOfEducation(startDate: Date, endDate: Date) {
  const startMonth = ("0" + (startDate.getMonth() + 1)).slice(-2);
  const startYear = startDate.getFullYear().toString().slice(-2);

  const endMonth = ("0" + (endDate.getMonth() + 1)).slice(-2);
  const endYear = endDate.getFullYear().toString().slice(-2);

  return `${startMonth}/${startYear} - ${endMonth}/${endYear}`;
}

export function calculatePeriodOfExperience(
  startDate: Date,
  endDate: Date | null,
) {
  if (startDate) {
    const startMonth = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const startYear = startDate.getFullYear().toString().slice(-2);

    let endMonth;
    let endYear;

    const today = `${new Date().getMonth()}-${new Date().getFullYear()}`;
    const experienceEndDate = `${endDate?.getMonth()}-${endDate?.getFullYear()}`;

    if (endDate === null) {
      endMonth = "Present";
      endYear = "";
    } else if (endDate) {
      endMonth = ("0" + (endDate.getMonth() + 1)).slice(-2);
      endYear = endDate.getFullYear().toString().slice(-2);
    }

    return `${startMonth}/${startYear} - ${endMonth}${
      endYear && `/`
    }${endYear}`;
  }
}

export function isValidEmail(email: string): boolean {
  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function isValidUrl(url: string): boolean {
  const urlRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

  return urlRegex.test(url);
}

export function isValidLinkedinUrl(url: string): boolean {
  const urlRegex =
    /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
  return urlRegex.test(url);
}

export function findNewEntities<T extends { id: string }>(
  existingData: T[],
  newData: T[],
) {
  return newData.filter((item: any) => {
    const exists = existingData.some((data) => data.id === item.id);
    return !exists;
  });
}

export function combinedTimelineData(
  education: Education[],
  experience: Experience[],
) {
  const combinedData = [];

  const transformedEducation = education.map((candidateEdu: Education) => ({
    id: candidateEdu.id,
    startDate: candidateEdu.startDate,
    endDate: candidateEdu.endDate,
    university: {
      id: candidateEdu.university.id,
      name: candidateEdu.university.abbreviation
        ? candidateEdu.university.abbreviation
        : candidateEdu.university.name,
    },
    postGrad: candidateEdu.postGrad,
    totalMonths:
      candidateEdu.startDate &&
      candidateEdu.endDate &&
      calculateTotalMonths(candidateEdu.startDate, candidateEdu.endDate),
    overlappingExperience: [] as any,
  }));

  const transformedExperience = experience
    .map((candidateExp: Experience) => {
      const overlappingEducation = transformedEducation.find(
        (education: any) =>
          education.startDate &&
          education.endDate &&
          candidateExp.startDate &&
          education.startDate <= candidateExp.startDate &&
          education.endDate >= candidateExp.startDate &&
          education.endDate.getTime() !== candidateExp.startDate.getTime(),
      );

      if (overlappingEducation) {
        overlappingEducation.overlappingExperience.push({
          id: candidateExp.id,
          title: candidateExp.title,
          startDate: candidateExp.startDate,
          endDate: candidateExp.endDate ?? new Date(),
          company: {
            id: candidateExp.company.id,
            name: candidateExp.company.name,
          },
          totalMonths:
            candidateExp.startDate &&
            calculateTotalMonths(
              candidateExp.startDate,
              candidateExp.endDate ?? new Date(),
            ),
        });

        return null;
      } else {
        return {
          id: candidateExp.id,
          title: candidateExp.title,
          startDate: candidateExp.startDate,
          endDate: candidateExp.endDate ?? new Date(),
          company: {
            id: candidateExp.company.id,
            name: candidateExp.company.name,
          },
          totalMonths:
            candidateExp.startDate &&
            calculateTotalMonths(
              candidateExp.startDate,
              candidateExp.endDate ?? new Date(),
            ),
        };
      }
    })
    .filter((exp: any) => exp !== null);

  combinedData.push(...transformedEducation, ...transformedExperience);

  const sortedData: any = combinedData.sort((a: any, b: any) => {
    if (a.startDate && b.startDate) {
      return a.startDate.getTime() - b.startDate.getTime();
    }
    return 0;
  });

  const additionalExperiences: any = [];

  sortedData.forEach((edu: any, idx: number) => {
    if (edu.university && edu.overlappingExperience.length) {
      let currentDate = edu.startDate;

      edu.overlappingExperience.sort(
        (a: any, b: any) => a.startDate.getTime() - b.startDate.getTime(),
      );

      const newOverlappingExperience = [];

      edu.overlappingExperience.forEach((exp: any) => {
        if (currentDate < exp.startDate) {
          newOverlappingExperience.push({
            totalMonths: calculateTotalMonths(currentDate, exp.startDate),
          });
        }

        if (exp.endDate > edu.endDate) {
          newOverlappingExperience.push({
            ...exp,
            endDate: exp.endDate,
            isOverlapped: true,
            totalMonths: calculateTotalMonths(exp.startDate, exp.endDate),
          });

          // additionalExperiences.push({
          //   ...exp,
          //   startDate: edu.endDate,
          //   isOverlapped: true,
          //   totalMonths: calculateTotalMonths(edu.endDate, exp.endDate),
          // });
        } else {
          newOverlappingExperience.push(exp);
        }

        currentDate = exp.endDate;
      });

      if (currentDate < edu.endDate) {
        newOverlappingExperience.push({
          totalMonths: calculateTotalMonths(currentDate, edu.endDate),
          gap: true,
        });
      }

      edu.overlappingExperience = newOverlappingExperience;
    }
  });

  const finalData = [...sortedData, ...additionalExperiences];

  finalData.sort((a: any, b: any) => {
    if (a.startDate && b.startDate) {
      return a.startDate.getTime() - b.startDate.getTime();
    }
    return 0;
  });

  for (let i = 0; i < finalData.length - 1; i++) {
    const endDate = finalData[i]?.endDate;
    const startDate = finalData[i + 1]?.startDate;

    let maxEndDate = endDate;

    if (finalData[i]?.overlappingExperience?.length) {
      finalData[i].overlappingExperience.forEach((exp: any) => {
        if (exp.endDate > maxEndDate) {
          maxEndDate = exp.endDate;
        }
      });
    }

    if (endDate && startDate && finalData[i]) {
      const gapMonths = calculateTotalMonths(maxEndDate, startDate);

      if (
        gapMonths &&
        gapMonths > 0 &&
        ((finalData[i].university &&
          finalData[i + 1].university &&
          gapMonths >= 12) ||
          (finalData[i].university && finalData[i + 1].company) ||
          (finalData[i].company && finalData[i + 1].company) ||
          (finalData[i].company &&
            finalData[i + 1].university &&
            gapMonths > 0))
      ) {
        finalData.splice(i + 1, 0, { totalMonths: gapMonths, gap: true });
        i++;
      }
    }
  }

  return finalData;
}

export function previewCareereData(
  education: Education[],
  experience: Experience[],
) {
  const combinedData = [];

  const transformedEducation = education.map((candidateEdu: Education) => ({
    id: candidateEdu.id,
    universityName: candidateEdu.university,
    period: candidateEdu.period,
    degreeType: candidateEdu.postGrad,
    isRelevant: candidateEdu.relevant,
    startDate: candidateEdu.startDate,
  }));

  const transformedExperience = experience.map((candidateExp: Experience) => ({
    id: candidateExp.id,
    companyName: candidateExp.company,
    period: candidateExp.period,
    isRelevant: candidateExp.relevant,
    title: candidateExp.title,
    startDate: candidateExp.startDate,
  }));

  combinedData.push(...transformedEducation, ...transformedExperience);

  const sortedData: any = combinedData.sort((a: any, b: any) => {
    if (a.startDate && b.startDate) {
      return a.startDate.getTime() - b.startDate.getTime();
    }
    return 0;
  });

  return sortedData;
}

export function isOverlappingEducation(
  editingEducationId: string | undefined,
  eduStartDate: Date,
  education: Education[],
): boolean {
  return education.some((edu) => {
    if (
      (!editingEducationId || edu.id !== editingEducationId) &&
      edu.startDate &&
      edu.endDate &&
      eduStartDate
    ) {
      return edu.startDate <= eduStartDate && eduStartDate <= edu.endDate;
    }
    return false;
  });
}

export function hasOverlappingEducation(education: Education[]): boolean {
  for (let i = 0; i < education.length; i++) {
    for (let j = i + 1; j < education.length; j++) {
      const edu1 = education[i];
      const edu2 = education[j];

      if (
        edu1.startDate &&
        edu1.endDate &&
        edu2.startDate &&
        edu2.endDate &&
        ((edu1.startDate <= edu2.endDate && edu1.endDate >= edu2.startDate) ||
          (edu2.startDate <= edu1.endDate && edu2.endDate >= edu1.startDate))
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isOverlappingExperience(
  editingExpId: string | undefined,
  expStartDate: Date,
  experience: Experience[],
): boolean {
  return experience.some((exp) => {
    if (
      (!editingExpId || exp.id !== editingExpId) &&
      exp.startDate &&
      exp.endDate &&
      expStartDate
    ) {
      return exp.startDate <= expStartDate && expStartDate <= exp.endDate;
    }
    return false;
  });
}

export function hasOverlappingExpinEdu(combinedData: any[]): boolean {
  for (const data of combinedData) {
    if (data.overlappingExperience && data.overlappingExperience.length > 0) {
      return true;
    }
  }
  return false;
}

export function hasCareerGap(combinedData: any[]): boolean {
  for (const data of combinedData) {
    if (data.gap) {
      return true;
    }
  }
  return false;
}

export function hasNoCurrentExperiences(experience: Experience[]): boolean {
  for (const exp of experience) {
    if (exp.currentlyWorking) {
      return false;
    }
  }
  return true;
}

// export function hasMultipleCurrentExperiences(
//   experience: Experience[],
// ): boolean {
//   const today = new Date();
//   let count = 0;

//   for (const exp of experience) {
//     const experienceEndDate = exp.endDate;
//     if (experienceEndDate) {
//       const endDate = new Date(experienceEndDate);
//       if (
//         endDate.getFullYear() === today.getFullYear() &&
//         endDate.getMonth() === today.getMonth() &&
//         endDate.getDate() === today.getDate()
//       ) {
//         count++;
//         if (count > 2) {
//           return true;
//         }
//       }
//     }
//   }
//   return false;
// }

export function hasExpectedGradDate(education: Education[]): string | null {
  let id: string | null = null;
  education.some((edu) => {
    const currentDate = new Date();
    if (edu.endDate && edu.endDate > currentDate) {
      id = edu.id;
      return true;
    }
  });
  return id;
}

export async function transformExperienceData(currentExperience: Experience[]) {
  const transformedData = await Promise.all(
    currentExperience.map(async (exp: Experience) => {
      const locationId = await CompanyLocationExists(
        exp.company.id,
        exp.company?.city?.id!,
      );

      return {
        id: exp.id,
        companyName: exp.companyName,
        title: exp.title,
        period: exp.period,
        description: exp.description,
        startDate: exp.startDate,
        endDate: exp.endDate,
        companyLocation: {
          id: locationId.id ?? uuidv4(),
          companyId: exp.company.id,
          cityId: exp.company.city?.id,
        },
        relevant: exp.relevant,
      };
    }),
  );

  return transformedData;
}

export function addSeventeenYears(date: Date) {
  const parsedDate = new Date(date);
  const newDate = addYears(parsedDate, 17);
  return newDate;
}

export function hasCurrentExperiences(
  experience: Experience[],
  expId: string,
): boolean {
  return experience.some((exp) => exp.id === expId && exp.currentlyWorking);
}

export function getOverlappingEntries(
  timelineData: any,
  experience: Experience[],
) {
  const overlappingEduExp: TimelineCandidateEducation[] = [];

  timelineData.forEach((data: any) => {
    if (data.overlappingExperience) {
      const filteredExperiences = experience.filter((exp: Experience) =>
        data.overlappingExperience.some((e: Experience) => e.id === exp.id),
      );

      if (filteredExperiences.length > 0) {
        overlappingEduExp.push({
          ...data,
          overlappingExperience: filteredExperiences,
        });
      }
    }
  });

  return overlappingEduExp;
}

export function getGapsWithAdjacentEntries(timelineData: any) {
  const gapsWithDetails: any = [];

  timelineData.forEach((data: any, index: number) => {
    if (data.gap) {
      const previousEntry = timelineData[index - 1] || null;
      const nextEntry = timelineData[index + 1] || null;

      gapsWithDetails.push({
        gapEntry: data,
        previousEntry,
        nextEntry,
      });
    }
  });

  return gapsWithDetails;
}

export function validateStepFields(
  step: number,
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>,
  education: Education[],
) {
  const { firstName, lastName, email, cv, dob, workLocations } =
    careerTimelineStates;

  switch (step) {
    case 1:
      return (
        firstName.trim().length === 0 ||
        lastName.trim().length === 0 ||
        email.trim().length === 0 ||
        // cv.trim().length === 0 ||
        !dob ||
        workLocations.length === 0
      );
    case 2:
      return education.length === 0;
    default:
      return false;
  }
}

export function validateEducation(
  educationState: ReturnType<typeof useEducationState>,
) {
  const {
    isStudent,
    universityName,
    newUniversityName,
    eduStartDate,
    eduEndDate,
    expectedGraduation,
    weeklyAvailability,
    newUniversityUrl,
    univCity,
    univSubCity,
  } = educationState;

  const isOtherUniversity =
    universityName?.name.toLowerCase() === "other" || !universityName?.name;

  const isOtherCity = univCity?.name.toLowerCase() === "other";

  return (
    (isOtherCity && !univSubCity) ||
    (isOtherUniversity
      ? !newUniversityName || !newUniversityUrl || !isValidUrl(newUniversityUrl)
      : !universityName?.name) ||
    !eduStartDate ||
    !eduEndDate ||
    (isStudent && (!expectedGraduation || !weeklyAvailability))
  );
}

export function validateExperience(
  experienceState: ReturnType<typeof useExperienceState>,
) {
  const {
    companyName,
    newCompanyName,
    expStartDate,
    expEndDate,
    relevantExperience,
    title,
    newCompanyUrl,
    isSelfEmployed,
    notMentioned,
    noUrl,
    compCity,
    compSubCity,
    currentlyWorking,
    compLocation,
    newCompLocation,
    compLocationSubCity,
  } = experienceState;

  const isOtherCompany = companyName?.name.toLowerCase() === "other";
  const isOtherCity = compCity?.name.toLowerCase() === "other";
  const isNoUrlValid = noUrl && (isSelfEmployed || notMentioned);
  const isNewCompLocationValid = !newCompLocation?.name && !compLocationSubCity;
  const isCompLocationValid =
    compLocation?.name.toLowerCase() === "other"
      ? isNewCompLocationValid
      : !compLocation?.name;

  return (
    (isOtherCity && !compSubCity) ||
    (isOtherCompany && !noUrl
      ? !newCompanyName || !newCompanyUrl || !isValidUrl(newCompanyUrl)
      : !noUrl && (!companyName?.name || isCompLocationValid)) ||
    (isOtherCompany && noUrl && !isNoUrlValid) ||
    (isOtherCompany && noUrl && notMentioned && !relevantExperience) ||
    !title ||
    (currentlyWorking ? !expStartDate : !expStartDate || !expEndDate)
  );
}

export function validateOnApply(
  education: Education[],
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>,
) {
  const { firstName, lastName, email, cv, workLocations, dob } =
    careerTimelineStates;

  const errors: string[] = [];

  if (!email.trim()) errors.push("Email");
  if (!firstName.trim() || !lastName.trim()) errors.push("Full Name");
  if (!dob) errors.push("Date of Birth");
  if (workLocations.length === 0) errors.push("Location");
  // if (!cv.trim()) errors.push("Resume");
  if (education.length === 0) errors.push("Education");

  return errors;
}

export function validatePersonalInfo(
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>,
) {
  const { firstName, lastName, email, cv, workLocations, dob } =
    careerTimelineStates;

  return (
    firstName.trim().length === 0 ||
    lastName.trim().length === 0 ||
    email.trim().length === 0 ||
    // cv.trim().length === 0 ||
    !dob ||
    workLocations.length === 0
  );
}
